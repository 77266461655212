<template>
  <div>
    <b-row>
        <b-col md="7" lg="8">   
            <b-card bg-variant="Success" class="text-center" v-if="submitted">
            <b-button
            variant="outline-success"
            :to="{ name: 'azienda-leads-in-attesa'}"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Ultime Richieste inviate
            </b-button>
            </b-card>

            <b-card bg-variant="Default" title="Come possiamo aiutarti ?" v-if="Caricato">

            <validation-observer ref="simpleRules">
                <b-form>
                    <b-form-group label="Tipo di Richiesta" label-for="id_tipo">
                        <validation-provider
                        #default="{ errors }"
                        name="Tipo di Richiesta"
                        rules="required"
                        >
                        <b-form-select
                            v-model="campiform.selected"
                            @change="onChange($event)"
                            :options="options"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <div class="mb-2" v-if="area_select_articolo_preventivo">
                      <div align="center" v-if="rows == []">
                        <b-spinner
                        style="width: 3rem; height: 3rem;"
                        class="mr-1"
                        variant="primary"
                        />
                      </div>

                      <div align="center" v-if="rows != []">
                        <div>
                          <!-- search input -->
                          <div class="custom-search d-flex justify-content-between">
                            <label>Seleziona i prodotti per richidere il preventivo</label>
                            <b-form-group>
                              <div class="d-flex align-items-center">
                                <label class="mr-1">Cerca</label>
                                <b-form-input
                                  v-model="searchTerm"
                                  placeholder="Ricerca Prodotti"
                                  type="text"
                                  class="d-inline-block"
                                />
                              </div>
                            </b-form-group>
                          </div>

                          <!-- table -->
                          <vue-good-table
                            :columns="columns"
                            :rows="rows"
                            :search-options="{
                              enabled: true,
                              externalQuery: searchTerm }"
                            :select-options="{
                              enabled: true,
                              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                              selectionInfoClass: 'custom-class',
                              selectionText: 'rows selected',
                              clearSelectionText: 'clear',
                              disableSelectInfo: true, // disable the select info panel on top
                              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                            }"
                            :pagination-options="{
                              enabled: true,
                              perPage:pageLength
                            }"
                            @on-select-all="onSelectAll"
                            @on-selected-rows-change="selectionChanged"
                          >
                            <template
                              slot="table-row"
                              slot-scope="props"
                            >
                              <!-- Column: Common -->
                              <span>
                                {{ props.formattedRow[props.column.field] }}
                              </span>
                            </template>

                            <!-- pagination -->
                            <template
                              slot="pagination-bottom"
                              slot-scope="props"
                            >
                              <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                  <span class="text-nowrap ">
                                    Visualizza 1 a
                                  </span>
                                  <b-form-select
                                    v-model="pageLength"
                                    :options="['3','5','10']"
                                    class="mx-1"
                                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                                  />
                                  <span class="text-nowrap"> di {{ props.total }} prodotti </span>
                                </div>
                                <div>
                                  <b-pagination
                                    :value="1"
                                    :total-rows="props.total"
                                    :per-page="pageLength"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @input="(value)=>props.pageChanged({currentPage:value})"
                                  >
                                    <template #prev-text>
                                      <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                      />
                                    </template>
                                    <template #next-text>
                                      <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                      />
                                    </template>
                                  </b-pagination>
                                </div>
                              </div>
                            </template>
                          </vue-good-table>
                        </div>

                      </div>
                    </div>

                    <b-form-group label="Titolo della Richiesta" label-for="titolo">
                        <validation-provider
                        #default="{ errors }"
                        name="Titolo"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.titolo"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Messaggio / Note" label-for="note">
                        <b-form-textarea
                            v-model="campiform.note"
                            placeholder="Descrivi brevemente la tua richiesta"
                            rows="3"
                        />
                    </b-form-group>

                <b-row>
                    <!-- submit button -->
                    <b-col md="12" class="text-center">
                    <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm(userData.id_azienda)"
                    >
                        Salva
                    </b-button>
                    </b-col>
                </b-row>
                </b-form>
            </validation-observer>

            </b-card>

        </b-col>
        <b-col md="5" lg="4">
            <b-card bg-variant="Info" title="Vuoi fare un ordine ?">
                <b-button
                block
                variant="outline-success"
                :to="{ name: 'catalogo-articoli'}"
                >
                    <feather-icon
                        icon="ShoppingCartIcon"
                        size="16"
                        class="mr-1"
                    />Articoli Selezionati
                </b-button>
                <b-button
                block
                variant="outline-primary"
                :to="{ name: 'catalogo-carrello'}"
                >
                    <feather-icon
                        icon="ShoppingCartIcon"
                        size="16"
                        class="mr-1"
                    />Il tuo Carrello
                </b-button>
            </b-card>

        </b-col>
    </b-row>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BCard, BInputGroup, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormSelect, BFormTextarea, BSpinner, 
BPagination } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, min
} from '@validations'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BFormTextarea,
    BSpinner,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueGoodTable,
  },
  data() {
    return {
      campiform: {
        titolo: '',
        note: '',
        selected: '1',
      },
      required,
      min,
      options: [ ],
      Caricato: false,
      submitted: false,
      userData: {},
      area_select_articolo_preventivo: false,

      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Codice',
          field: 'codice',
        },
        {
          label: 'Articolo',
          field: 'descrizione',
        },
      ],
      rows: [],
      searchTerm: '',
      articoli_selezionati: [],
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    console.log('richiedi id_azienda => '+this.userData.id_azienda)

    this.$http.get('v2/azienda/tipi_leads/')
    .then(response => {
        //console.log("risposta ??? -> "+response.data.dati_risposta[0].value); 
        //console.log(response.data);
        //console.log("----------------------------------------")

        if(response.status===200){
            this.options = response.data.dati_risposta

            //seleziono nel select la prima voce restiuita
            this.campiform.selected = response.data.dati_risposta[0].value
        } else {
            this.showToast('danger','AlertCircleIcon','Errore nel caricamento dei Tipi di Richiesta, prego contattare la nostra assistenza','','top-center')
        }

        this.Caricato = true;
    })
  },
  methods: {
    onChange(event) {
        //console.log("speriamo bene ... "+this.campiform.selected)

        //in caso di selezione "richiesta preventivo => visualizza l'area dove ci sarà la tabella"
        if(this.campiform.selected == 6){

          this.area_select_articolo_preventivo = true
          this.campiform.titolo = 'Richiesta preventivo per i seguenti Articoli'
          
          //richiesta dati lista prodotti per i quali richiedere un preventivo          
          this.$http.get('v2/articoli/lista_articoli_per_selezione/')
            .then(response => { 
                console.log(response.data.dati);

                this.rows = response.data[1].righe
            })

          /*
          this.$http.get('/good-table/basic').then(res => { 
            console.log(res.data)
            this.rows = res.data
          })
          */

        } else {
          //nascondi area
          this.area_select_articolo_preventivo = false
          //cancella titolo
          this.campiform.titolo = ''
        }
    },
    validationForm(id_azienda_passata) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          console.log('start invio form ... spriamo bene')
          console.log('Titolo Richiesta -> '+this.campiform.titolo)

          //aggiungi ID_azienda al post in partenza
          this.campiform = Object.assign({}, this.campiform, {id_azienda: parseInt(id_azienda_passata)});

          //console.log(this.campiform)
          
          if((this.campiform.selected == 6) && (this.articoli_selezionati != [])){
            //è stato selesionato almeno un'articolo
            let testo_preventivo = '';
            this.articoli_selezionati.selectedRows.forEach(element => {
              //console.log("riga n. "+element.codice+" -> "+element.descrizione)
              testo_preventivo+="codice art.: "+element.codice+" | "+element.descrizione+"\n";
            })

            this.campiform.note = testo_preventivo;
          }
          
          this.$http.post('v2/azienda/salvanuovolead', {
            dati_form: this.campiform 
            }).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if(response.data.statusCode===200){
                //risposta positiva
                if(response.data.dati_risposta > 0){
                  //redirect verso dettaglio lead appena salvato
                  this.$router.replace('/azienda/leadinfo/'+response.data.dati_risposta)
                    .then(() => {
                      this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                    })
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.campiform = {}; //svuota i campi del form
                  this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                  this.area_select_articolo_preventivo = false;
                  this.rows = [];
                  this.submitted = true;
                }
                
              } else {
                //risposta negativa (errore sul server)
                this.showToast('danger','AlertCircleIcon',response.data.error.descrizione,'','top-center')
                this.area_select_articolo_preventivo = false;
                //this.rows = [];
                this.submitted = false;
              }
            }).catch(e => {
              console.log(e);
            });
          
        }
      })
    },
    showToast(variant, icona, titolo, testo, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titolo,
          icon: icona,
          text: testo,
          variant,
        },
      },
      {
        position,
      })
    },
    onSelectAll(params) {
      // params.selected - whether the select-all checkbox is checked or unchecked
      // params.selectedRows - all rows that are selected (this page)
      //console.log("speriamo bene ...")
      //console.log(params)

      //console.log(this.articoli_selezionati)

      //aggiungi articoli selzioanti alla variabile generale
      this.articoli_selezionati = Object.assign({}, this.articoli_selezionati, params);

      //console.log("----- dopo ....")
      //console.log(this.articoli_selezionati)      
    },
    selectionChanged(params) {
      // params.selectedRows - all rows that are selected (this page)

      //aggiungi articoli selzioanti alla variabile generale
      this.articoli_selezionati = Object.assign({}, this.articoli_selezionati, params);

      //console.log("----- dopo ....")
      //console.log(this.articoli_selezionati)      
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
