var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"7","lg":"8"}},[(_vm.submitted)?_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"Success"}},[_c('b-button',{attrs:{"variant":"outline-success","to":{ name: 'azienda-leads-in-attesa'}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CornerUpLeftIcon","size":"16"}}),_vm._v("Ultime Richieste inviate ")],1)],1):_vm._e(),(_vm.Caricato)?_c('b-card',{attrs:{"bg-variant":"Default","title":"Come possiamo aiutarti ?"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-form-group',{attrs:{"label":"Tipo di Richiesta","label-for":"id_tipo"}},[_c('validation-provider',{attrs:{"name":"Tipo di Richiesta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options,"state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.campiform.selected),callback:function ($$v) {_vm.$set(_vm.campiform, "selected", $$v)},expression:"campiform.selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3480369133)})],1),(_vm.area_select_articolo_preventivo)?_c('div',{staticClass:"mb-2"},[(_vm.rows == [])?_c('div',{attrs:{"align":"center"}},[_c('b-spinner',{staticClass:"mr-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary"}})],1):_vm._e(),(_vm.rows != [])?_c('div',{attrs:{"align":"center"}},[_c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-between"},[_c('label',[_vm._v("Seleziona i prodotti per richidere il preventivo")]),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Cerca")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Ricerca Prodotti","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
                            enabled: true,
                            externalQuery: _vm.searchTerm },"select-options":{
                            enabled: true,
                            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                            selectionInfoClass: 'custom-class',
                            selectionText: 'rows selected',
                            clearSelectionText: 'clear',
                            disableSelectInfo: true, // disable the select info panel on top
                            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                          },"pagination-options":{
                            enabled: true,
                            perPage:_vm.pageLength
                          }},on:{"on-select-all":_vm.onSelectAll,"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Visualizza 1 a ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3','5','10']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" di "+_vm._s(props.total)+" prodotti ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,1752969435)})],1)]):_vm._e()]):_vm._e(),_c('b-form-group',{attrs:{"label":"Titolo della Richiesta","label-for":"titolo"}},[_c('validation-provider',{attrs:{"name":"Titolo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.titolo),callback:function ($$v) {_vm.$set(_vm.campiform, "titolo", $$v)},expression:"campiform.titolo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4156257496)})],1),_c('b-form-group',{attrs:{"label":"Messaggio / Note","label-for":"note"}},[_c('b-form-textarea',{attrs:{"placeholder":"Descrivi brevemente la tua richiesta","rows":"3"},model:{value:(_vm.campiform.note),callback:function ($$v) {_vm.$set(_vm.campiform, "note", $$v)},expression:"campiform.note"}})],1),_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm(_vm.userData.id_azienda)}}},[_vm._v(" Salva ")])],1)],1)],1)],1)],1):_vm._e()],1),_c('b-col',{attrs:{"md":"5","lg":"4"}},[_c('b-card',{attrs:{"bg-variant":"Info","title":"Vuoi fare un ordine ?"}},[_c('b-button',{attrs:{"block":"","variant":"outline-success","to":{ name: 'catalogo-articoli'}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ShoppingCartIcon","size":"16"}}),_vm._v("Articoli Selezionati ")],1),_c('b-button',{attrs:{"block":"","variant":"outline-primary","to":{ name: 'catalogo-carrello'}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ShoppingCartIcon","size":"16"}}),_vm._v("Il tuo Carrello ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }